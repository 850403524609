import React, { Fragment } from "react"
import styled from "styled-components"
import { down } from "styled-breakpoints"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"
//DATA
import { godziny } from "../assets/data/kontakt"
//STYLES
import { H1, H4, H2, H3, H5, LabelBW, Text } from "../utils/typo"
import {
  Container,
  CardWrapper,
  Section,
  CardWrapperFull,
  ContainerHalf,
  Pattern,
  ContainerMob,
} from "../utils/utils"
//COMPONENTS
import Seo from "../components/seo/SEO"
import Layout from "../layout/layout"
import Button from "../components/button/Button"

const BanerInfo = styled(ContainerHalf)`
  padding-top: 5rem;
  padding-bottom: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${down("xs")} {
    padding-bottom: 2rem;
  }
`

const BanerFoto = styled(CardWrapperFull)`
  position: relative;
  top: 0;
`

const Important = styled(Link)`
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin-top: -7rem;
  position: relative;
  z-index: 1;

  ${down("xs")} {
    grid-template-columns: 1fr;
  }

  div:nth-of-type(2) {
    background-color: ${props => props.theme.color.prim3};
    padding: 1.5rem;
    transition: all ease-in-out 0.35s;

    :hover {
      background-color: ${props => props.theme.color.prim1};
    }

    div {
      display: flex;
      justify-content: space-between;
    }

    p,
    h2,
    h5,
    label {
      color: white;
    }

    label {
      ${down("xs")} {
        display: none;
      }
    }

    h2 {
      line-height: 1;
      margin-bottom: 1rem;
    }
  }
`

const Lines = styled(Pattern)`
  height: ${props => props.height || "auto"};
  position: relative;
`

const Lines1 = styled(Pattern)`
  height: ${props => props.height || "auto"};
  position: relative;
  padding-bottom: 200px;
  margin-bottom: -200px;
`

const GodzinyWrapper = styled.div`
  background-color: ${props => props.theme.color.grey5};
  padding: 2rem;

  ${down("md")} {
    padding: 1rem;
  }

  > p:first-of-type {
    color: ${props => props.theme.color.grey7};
    text-align: left;
  }

  p,
  h3 {
    color: white;
    text-align: center;
  }

  h3 {
    margin-top: 2rem;
    ${down("md")} {
      margin-top: 0.7rem;
      line-height: 1;
    }
  }
`

const GodzinyOtwarcia = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 50px auto;
  justify-content: center;
  row-gap: 1rem;
  ${down("sm")} {
    margin-top: 0.7rem;
    row-gap: 0rem;
  }
`

const Card = styled(Link)`
  height: 100%;
  background-color: ${props => props.bgcolor || props.theme.color.grey9};
  outline: 1px solid lightgrey;
  outline-offset: -0.5px;
  padding: 2rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h4 {
    line-height: 1;
    margin-bottom: 1rem;
  }

  &:hover {
    background-color: ${props => props.theme.color.base1};
  }
`

const CardWrapperG = styled(CardWrapper)`
  position: relative;

  ${down("sm")} {
    > div:nth-of-type(1),
    > div:nth-of-type(2) {
      display: none;
    }
  }
`

const ImgCard = styled.div`
  position: relative;
  ::before {
    content: "";
    position: absolute;
    background-color: ${props => props.bgcolor || "transparent"};
    opacity: 0.3;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
  }
`

const IndexPage = ({
  data: {
    oferta: {
      frontmatter: { oferta },
    },
    realizacje: {
      frontmatter: {
        realizacjeLabel,
        realizacjeTile,
        realizacjeIntro,
        realizacje,
      },
    },
  },
}) => {
  return (
    <div>
      <Seo title="Home" slug="" />
      <Layout>
        <Section>
          <BanerFoto xl="2" l="2" m="2">
            <BanerInfo>
              <LabelBW as="h1" mb="2rem">
                DRUKARNIA IKS — DRUK PLOT KSERO
              </LabelBW>
              <H1
                as="h2"
                xl="65px"
                l="60px"
                m="55px"
                s="50px"
                xs="45px"
                lineHeight="1.1"
                data-sal="fade"
                data-sal-duration="800"
              >
                Plakaty, projekty, ulotki, postery, plansze, wizytówki
                <br /> materiały reklamowe.
              </H1>
              <H4 as="h3" weight="300" mt="2rem">
                Druk cyfrowy wielkoformatowy i małoformatowy
                <br />
                usługi dodatkowe, materiały biurowe.
              </H4>
              <Button link="/oferta">Oferta druku</Button>
            </BanerInfo>
            <StaticImage
              placeholder="blurred"
              alt="Projektowanie graficzne - ikona"
              src="../assets/images/1.jpg"
              layout="fullWidth"
              objectFit="cover"
            />
          </BanerFoto>
          <Lines1>
            <Container>
              <Important>
                <div></div>
                <div>
                  <div>
                    <H2>ROK AKADEMICKI 2024/2025!</H2>
                    <LabelBW textAlign="right" as="label">
                      1 października 2024
                    </LabelBW>
                  </div>

                  {/* <H5>
                    Godziny otwarcia: 7:00 - 17:00
                  </H5> */}
                  <H5 mb="1rem">
                    ŻYCZYMY POWODZENIA W NOWYM ROKU AKADEMICKIM. <br />
                  </H5>

                  <Text xl="20px" l="20px" m="20px" s="20px" xs="20px">
                    Chętnie wydrukujemy, doradzimy i zaopatrzymy w materiały
                    biurowe i uśmiech.
                    <br />
                    Serdecznie zapraszamy.<b></b>
                  </Text>
                  {/* <H5 mt="1rem">Zapraszamy!</H5> */}
                </div>
              </Important>
            </Container>
          </Lines1>
        </Section>

        <Section pt="0">
          <CardWrapper xl="2" l="2" m="2" s="1" colGap="0">
            <GodzinyWrapper data-sal="slide-right" data-sal-duration="800">
              <LabelBW>informacje</LabelBW>
              <H3>Godziny otwarcia drukarni</H3>
              <GodzinyOtwarcia>
                {godziny.slice(0, 5).map(dzien => {
                  return (
                    <Fragment key={dzien.day}>
                      <H5 as="p" weight="700">
                        {dzien.day}
                      </H5>
                      <H5 as="p">
                        {dzien.godzinaOd} - {dzien.godzinaDo}
                      </H5>
                    </Fragment>
                  )
                })}
              </GodzinyOtwarcia>
              <H5 as="p" mb="2rem">
                weekend - nieczynne
              </H5>
            </GodzinyWrapper>

            <CardWrapperG xl="2" l="2" m="2" s="2">
              <ImgCard bgcolor="red">
                <StaticImage
                  placeholder="blurred"
                  alt="Projektowanie graficzne - ikona"
                  src="../assets/images/index1.JPG"
                  layout="fullWidth"
                  objectFit="contain"
                  aspectRatio="1"
                />
              </ImgCard>
              <ImgCard>
                <StaticImage
                  placeholder="blurred"
                  alt="Projektowanie graficzne - ikona"
                  src="../assets/images/index2.jpg"
                  layout="fullWidth"
                  objectFit="contain"
                  aspectRatio="1"
                />
              </ImgCard>
              <Card to={"/oferta#" + oferta[0].id}>
                <div>
                  <H4>{oferta[0].title}</H4>
                  <Text>{oferta[0].intro}</Text>
                </div>

                <Button to={"/oferta#" + oferta[0].id}>Więcej</Button>
              </Card>
              <Card to={"/oferta#" + oferta[1].id}>
                <div>
                  <H4>{oferta[1].title}</H4>
                  <Text>{oferta[1].intro}</Text>
                </div>

                <Button to={"/oferta#" + oferta[1].id}>Więcej</Button>
              </Card>
            </CardWrapperG>
          </CardWrapper>
        </Section>
        <Lines>
          <CardWrapper>
            {oferta
              .filter(allItem => allItem.id > 2)
              .map(item => (
                <Card key={item.id} to={"/oferta#" + item.id}>
                  <div>
                    <H4>{item.title}</H4>
                    <Text>{item.intro}</Text>
                  </div>

                  <Button to={"/oferta#" + item.id}>Więcej</Button>
                </Card>
              ))}
          </CardWrapper>
        </Lines>

        <Section pt="6rem" pb="3rem">
          <ContainerMob>
            <LabelBW mb="2rem">{realizacjeLabel}</LabelBW>
            <H2 mb="1rem" lineHeight="1.1">
              {realizacjeTile}
            </H2>
            <Text mt="2rem" mb="2rem">
              {realizacjeIntro}
            </Text>
          </ContainerMob>

          <CardWrapper>
            {realizacje.map(realizacja =>
              realizacja.images.map(img => (
                <ImgCard key={img.file.name}>
                  <GatsbyImage
                    image={img.file.childImageSharp.gatsbyImageData}
                    alt={img.alt}
                    objectFit="contain"
                  />
                </ImgCard>
              ))
            )}
          </CardWrapper>
        </Section>
      </Layout>
    </div>
  )
}
export default IndexPage

export const query = graphql`
  query {
    realizacje: mdx(slug: { eq: "realizacje" }) {
      frontmatter {
        realizacjeLabel
        realizacjeTile
        realizacjeIntro
        realizacje {
          title
          images {
            alt
            file {
              name
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
    oferta: mdx(slug: { eq: "oferta" }) {
      frontmatter {
        oferta {
          heading
          id
          span
          intro
          link
          lista
          text
          title
          mainImage {
            alt
            file {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  transformOptions: { fit: CONTAIN }
                  placeholder: BLURRED
                )
              }
            }
          }
          logo {
            alt
            file {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1
                  transformOptions: { fit: CONTAIN }
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  }
`
